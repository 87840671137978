<template>
  <div class="main-conent main-conent-screen main-conent-bgFFF main-conent-borderradius">
    <div class="screen-header">
        <el-row>
            <el-col :span="8">
                <el-button type="primary" icon="el-icon-plus">预制按钮</el-button>
            </el-col>
            <el-col :span="16" class="text-right">
                <el-input
                    class="margin-l10"
                    style="width:200px;"
                    placeholder="请输入搜索内容"
                    clearable
                    v-model="queryParams.search">
                    <i slot="suffix" class="el-input__icon el-icon-search cursor-pointer" @click="getList()"></i>
                </el-input>
            </el-col>
        </el-row>              
    </div>

    <screen-table
        class="screen-conent"
        table-class="custom-table"
        header-row-class-name="custom-table-header"
        :data="tableData">
            <el-table-column
                fixed
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column
                prop="id"
                label="主键"
                width="60">
            </el-table-column>
            <el-table-column
                prop="shareId"
                label="分享id"
                width="140">
            </el-table-column>
            <el-table-column
                prop="title"
                label="视频标题"
                width="120">
            </el-table-column>
            <el-table-column
                prop="itemId"
                label="加密视频ID"
                width="120">
            </el-table-column>
            <el-table-column
                prop="videoId"
                label="真实视频ID"
                width="120">
            </el-table-column>
            <el-table-column
                prop="updateUserId"
                label="修改人"
                width="60">
            </el-table-column>
            <el-table-column
                prop="updateTime"
                label="修改时间">
                <template slot-scope='scope'>
                    <span>{{ parseTime(scope.row.updateTime) }}</span>
                </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="150">
                <template slot-scope="scope">
                    <el-button type="text">查看</el-button>
                    <el-button type="text" @click.native="handleCreateSchema(scope.row)">生成Schema</el-button>
                </template>
            </el-table-column>
    </screen-table>

    <div class="screen-footer text-right">
        <el-pagination
            background
            layout="sizes, prev, pager, next, total"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            @current-change="handlePageNo"
            @size-change="handlePageSize"
        >
        </el-pagination>
    </div>

    <!-- 分享发布 -->
    <el-dialog title="分享发布二维码" :visible.sync='open' width='500px' :before-close='cancel'>
        <div>
            <img :src="qrcodeImage" alt="">
        </div>
    </el-dialog>


  </div>
</template>
<script>

import ScreenTable from '@/components/ScreenTable';
import { getSharePage, getShareUrl } from '@/service/dyShare';

export default {
  components: {
      ScreenTable
  },
  data () {
    return {
      // 查询参数
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        search: undefined
      },
      total: 0,
      input1: '',
      svalue2: '',
      searchDrawer: false,
      tableData: [],
      open: false,
      qrcodeImage: ""
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      getSharePage(this.queryParams).then(response => {
        this.tableData = response['data']['records'];
        this.total = response['data']['total'];
      });
    },
    handlePageNo(pageNo) {
        this.queryParams = {
            ...this.queryParams,
            pageNo
        };
        this.getList();
    },
    handlePageSize(pageSize) {
        this.queryParams = {
            ...this.queryParams,
            pageSize
        };
        this.getList();
    },
    handleCreateSchema(row){
      getShareUrl({id: row.id}).then(response => {
        this.open = true;
        this.qrcodeImage = window.URL.createObjectURL(new Blob([response.data]));
        console.log(this.qrcodeImage, "工作流图片");
      });
    },
    cancel(){
        this.open = false;
        this.getList();
    }
  }
};
</script>